<script setup lang="ts">
import type { PropType } from '@vue/runtime-core';

const uid = Math.random().toString(36).substring(2);

export interface BulletItem {
  heading?: string;
  body?: string;
}

defineProps({
  items: {
    type: Array as PropType<BulletItem[]>,
  },
});
</script>

<template>
  <ul>
    <li v-for="(item, i) in items" :key="i">
      <div class="bullet">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="none">
          <defs>
            <mask :id="`mask-${uid}-${i}`">
              <circle cx="32" cy="32" r="32" fill="white" />
              <text
                x="50%"
                y="50%"
                dominant-baseline="middle"
                text-anchor="middle"
                fill="black"
                font-size="22"
              >
                {{ i + 1 }}
              </text>
            </mask>
          </defs>
          <rect
            :mask="`url(#${`mask-${uid}-${i}`})`"
            fill="currentColor"
            width="64"
            height="64"
          />
        </svg>
      </div>
      <div class="content">
        <slot :item="item">
          <div class="heading" v-if="item.heading">{{ item.heading }}</div>
          <p class="body" v-if="item.body">{{ item.body }}</p>
        </slot>
      </div>
    </li>
  </ul>
</template>

<style scoped lang="scss">
@import '@/assets/css/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

ul {
  padding-left: 0;
  list-style: none;
}

li {
  margin-bottom: 80px;
  display: flex;

  @include media-breakpoint-down(sm) {
    margin-bottom: 48px;
  }

  .bullet {
    color: $primary;
    margin-right: 48px;

    svg {
      width: 64px;
      height: 64px;
    }

    @include media-breakpoint-down(sm) {
      margin-right: 24px;
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }

  .heading {
    font-size: 22px;
    font-weight: 700;
    margin: 16px 0;
    white-space: pre-wrap;

    @include media-breakpoint-down(sm) {
      margin: 8px 0;
    }
  }

  .body {
    flex-grow: 1;
    white-space: pre-wrap;
  }
}
</style>
