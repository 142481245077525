<script setup lang="ts">
import { NuxtLink } from '#components';
import dayjs from 'dayjs';

const router = useRouter();

const { data: items, pending } = await useNewsList({
  sort: '-date_published',
  limit: 3,
});
const loading = useLoadingIndicator();
watch([pending], ([pending]) => {
  loading.isLoading.value = pending;
});

function formatDate(date?: string | number | Date) {
  return date ? dayjs(date).format('YYYY . MM') : '-';
}
</script>

<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <h1 class="main-section-heading mb-4">News</h1>
          <CtaButton :tag="NuxtLink" to="/news" class="mb-5">
            View All
          </CtaButton>
        </div>
        <div class="col-lg-9">
          <ol class="news">
            <template v-if="items?.length">
              <NuxtLink
                :to="`/news/${item.id}`"
                v-for="item in items"
                :key="item.id"
                class="news-preview-row"
              >
                <li v-for="item in items" :key="item.id">
                  <div class="date">{{ formatDate(item.date_published) }}</div>
                  <div class="title">{{ item.title }}</div>
                </li>
              </NuxtLink>
            </template>
            <template v-else>
              <li class="justify-content-center">No news</li>
            </template>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

section {
  background: #f6f6f6;
  padding: 180px 16px;
}

::v-deep(.cta) {
  padding: 1em 4em;
}

.news {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 18px;

  &-preview-row {
    &:hover,
    &:focus {
      li {
        transform: scaleX(1.02);
      }
    }
  }

  li {
    &:first-child {
      border-top: 1px solid #818181;
    }

    border-bottom: 1px solid #818181;
    padding: 1.5em 0;
    display: flex;
    flex-wrap: wrap;
    transition: transform 0.1s ease-out;

    > * {
      padding: 0 2em;
    }

    .date {
      white-space: nowrap;
      min-width: 192px; // measured by hand from a "0000 . 00 . 00" value

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .title {
      flex: 1;
    }
  }
}
</style>
