<script setup>
const baseURL = useRuntimeConfig().app.baseURL;
const img2270156223 = `${baseURL}img/stock/2270156223.jpg`;
const img2274542555 = `${baseURL}img/stock/2274542555.jpg`;

const showMore = ref(false);
</script>

<template>
  <a id="scope-of-services" />
  <section>
    <div class="container py-5">
      <h1 class="main-section-heading">Scope of Services of CMLA</h1>
      <h2 class="main-section-caption left">
        We offer MLA series of seminars, leadership and management courses for
        entrepreneurs, professionals, and executives for both their personal
        developments and organizational growth.
      </h2>
    </div>
    <OverflowImageRow
      :image="img2270156223"
      imagePosition="left"
      imageHeight="550px"
      :imageAspectRatio="4 / 3"
      :overflow="false"
      :image-col-size="4"
    >
      <div>
        <h1 class="main-section-heading">Services Overview</h1>
        <p>
          The Objective of the Centre for Management as a Liberal Art (CMLA) is
          to enhance management education with liberal arts perspectives.
        </p>
        <p>
          To carry out this mission, the CMLA aims to develop Management as a
          Liberal Art (MLA) management education materials, to offer research
          reports for companies to consider, to provide conferences and seminars
          for knowledge transfer related to MLA, and to award deserving
          companies in Hong Kong and the Greater Bay Area some MLA recognitions.
          This is in line with the values of responsible management and liberal
          arts education promoted by The Hang Seng University of Hong Kong
          (HSUHK).
        </p>
      </div>
    </OverflowImageRow>
    <OverflowImageRow
      :image="img2274542555"
      imagePosition="left"
      imageHeight="550px"
      :imageAspectRatio="16 / 9"
      :overflow="false"
      :image-col-size="12"
      :text-col-size="12"
      no-padding
    >
      <div style="padding-top: 60px">
        <p>
          The CMLA will offer action-oriented learning rather than focusing only
          on research. We will mainly conduct activities including, but not
          limited to, MLA case studies, advocacy and teaching. MLA education,
          teaching and research, include but are not limited to the following
          topics: leadership and its development, social ecology and
          cross-disciplinary learning, ESG practices, innovation and
          entrepreneurship, managing NGOs for high social impact, etc.
        </p>
        <p class="mb-4" v-if="showMore">
          The CMLA will undertake case-study research on MLA in both western and
          eastern cultural contexts, and develop a series of online/offline
          courses, seminars and forums on the advocacy and teaching of MLA. The
          case studies may lead to MLA awards and the formation of
          self-assessment tools.
        </p>
        <p class="mb-4" v-if="showMore">
          With the aim of knowledge exchange and transfer, the CMLA will
          function as an incubator for innovative projects and knowledge
          transfer for management and leadership education and training.
          Train-the-trainer programs will be conducted to build up a team of
          expert trainers.
        </p>
      </div>
    </OverflowImageRow>
    <div class="container text-center">
      <MoreButton
        style="margin-bottom: 100px"
        @click="showMore = !showMore"
        :expanded="showMore"
      />
    </div>
  </section>
</template>

<style scoped lang="scss">
@import '@/assets/css/variables.scss';

section {
  padding: 100px 0;
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color: $primary;
  --bs-body-color: white;
  --bs-body-bg: #{$primary};

  .main-section-heading {
    color: white;
  }
}
</style>
