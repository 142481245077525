<script setup>
const baseURL = useRuntimeConfig().app.baseURL;
const img1790720117 = `${baseURL}img/stock/1790720117.jpg`;

// region fixtures
const bulletItems = [
  {
    heading: 'Compassion',
    body:
      'Compassion is the act of transforming love towards others into actions ' +
      'that are beneficial to the well beings of others.',
  },
  {
    heading: 'Integrity',
    body: 'Integrity is the ability of differentiating right from wrong, and follows the right path.',
  },
  {
    heading: 'Truth',
    body:
      'Truth initiates evolvement of human and the world. Sometimes truth appears as a tangible form; ' +
      'sometimes it hides away from physical eyes and senses, and lives in the belief of human. ' +
      'The quest for, as well as exploration and recognition of truth, is the spiritual activity ' +
      'that is uniquely human, and differentiates human from other species. Human also grow and ' +
      'develop in the process.',
  },
];
// endregion
</script>

<template>
  <section>
    <a id="our-mission" />
    <OverflowImageRow :image="img1790720117" imagePosition="right">
      <div>
        <h1 class="main-section-heading">Our Mission</h1>
        <p>
          The establishment of the CMLA at HSUHK is in response to the current
          challenges. It will try to fill the gaps in management education,
          create value for society and help maintain its sustainability. Its
          activities will be based on Drucker’s MLA philosophy, mingled with
          Eastern and Western cultural contexts. We hope that the
          multi-disciplinary approach of MLA learning could prepare our future
          leadership with the capabilities to navigate the many uncertainties we
          now face.
        </p>
      </div>
    </OverflowImageRow>
    <div class="py-5">
      <div class="container">
        <h1 class="main-section-heading">Our Vision</h1>
        <h2 class="main-section-caption left">
          To enable individual achieving, organization performing and society
          functioning through the learning and practice of Management as a
          Liberal Art as envisioned by Peter F. Drucker
        </h2>
      </div>
    </div>
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-4 col-xl-6">
          <h1 class="main-section-heading mb-5">Our Values</h1>
        </div>
        <div class="col-lg-8 col-xl-6">
          <BulletList :items="bulletItems" />
        </div>
      </div>
    </div>
    <!--    <div class="container">-->
    <!--      <div class="block-callout"></div>-->
    <!--    </div>-->
  </section>
</template>

<style scoped lang="scss">
section {
  padding: 100px 0;
  font-size: 18px;
  font-weight: 500;
  background-color: #f6f6f6;
  --bs-body-bg: #f6f6f6;
}
</style>
