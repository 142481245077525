<script setup lang="ts">
const uid = Math.random().toString(36).substring(2);

defineProps({
  expanded: Boolean,
});
</script>

<template>
  <button :class="{ expanded }">
    <svg
      class="icon"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask :id="`mask-${uid}`">
          <circle cx="18" cy="18" r="18" fill="white" />
          <path
            d="M11 16L18.2857 23L26 16"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </mask>
      </defs>
      <rect
        :mask="`url(#${`mask-${uid}`})`"
        fill="currentColor"
        width="36"
        height="36"
      />
    </svg>
    <span style="font-size: 18px">{{ expanded ? 'Less' : 'Show' }}</span>
  </button>
</template>

<style scoped lang="scss">
button {
  display: inline-flex;
  background: transparent;
  border: none;
  align-items: center;
  color: inherit;

  .icon {
    transition: all 0.2s ease-out;
  }

  &.expanded .icon {
    rotate: -180deg;
  }

  span {
    transition: padding 0.2s ease-out;
    padding-left: 14px;
  }

  &:hover,
  &:focus {
    span {
      padding-left: 28px;
    }
  }
}
</style>
