<script setup>
const baseURL = useRuntimeConfig().app.baseURL;
const img414478405 = `${baseURL}img/stock/414478405.jpg`;
const img146701646 = `${baseURL}img/stock/146701646.jpg`;
</script>

<template>
  <a id="about" />
  <section>
    <div class="py-5">
      <div class="container">
        <h1 class="main-section-heading">About CMLA</h1>
        <h2 class="main-section-caption left">
          Welcome to The Centre for Management as a Liberal Art (CMLA).
        </h2>
      </div>
    </div>
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-6 offset-lg-6">
          The Centre for Management as a Liberal Art (CMLA) is a joint
          initiative of The Hang Seng University of Hong Kong (HSUHK) and MLA
          Academy. Its mission is to promote the legacy of Peter F. Drucker, the
          father of modern management. In particular, the philosophy, principles
          and practices of Management as a Liberal Art (MLA).
        </div>
      </div>
    </div>
    <OverflowImageRow
      :image="img414478405"
      imagePosition="left"
      imageHeight="calc(50vw * 3 / 4)"
      :imageAspectRatio="3 / 2"
    >
      <div class="paragraph-with-underline">
        Drucker advocated that management should be regarded as a liberal art,
        that is, a discipline that integrates knowledge from various fields of
        human inquiry and applies it to the practice of managing organizations
        and society. Drucker also envisioned that management should aim at
        creating a Functioning Society, that is, a society that fosters social
        responsibility, innovation, and human dignity across all three sectors:
        business, government, and civil society.
      </div>
    </OverflowImageRow>
    <OverflowImageRow
      :image="img146701646"
      imagePosition="right"
      imageHeight="calc(50vw * 3 / 4)"
      :imageAspectRatio="3 / 2"
    >
      <div class="paragraph-with-underline">
        The CMLA seeks to advance this vision by providing high-quality
        education and applied research on leadership in management, with a focus
        on the comparative analysis of Western and Oriental cultures and their
        implications for effective management.
      </div>
    </OverflowImageRow>
  </section>
</template>

<style scoped lang="scss">
section {
  padding: 100px 0;
  font-size: 18px;
  font-weight: 500;
}
</style>
