<script setup lang="ts">
import type { ProfileItem } from '~/components/ProfileGrid.vue';
import { readItems, readFiles } from '@directus/sdk';

const showProfile = ref<ProfileItem>();

const { cms, convertAssetUrl } = useCms();

const { data: committees } = await useAsyncData(
  'cmla_executive_committees',
  async () => {
    const groups = await cms.request(
      readItems('cmla_executive_committees', {
        fields: ['*'],
        sort: 'sort',
      }),
    );

    const allPhotos = groups
      .map((g) => g.items.map((i) => i.photo?.key))
      .reduce((a, b) => a.concat(b), [])
      .filter((x) => x) as string[];
    const files = await cms.request(
      readFiles({
        fields: ['id', 'filename_download'],
        filter: { id: { _in: allPhotos } },
      }),
    );

    // fill in the photo URLs
    return groups.map((g) => {
      return {
        ...g,
        items: g.items.map((i) => {
          const file = files.find((f) => f.id === i.photo!.key);
          return {
            ...i,
            photo: i.photo ? convertAssetUrl(file) : undefined,
            avatar: i.photo
              ? convertAssetUrl(file, { key: 'medium' })
              : undefined,
          };
        }),
      };
    });
  },
);
</script>

<template>
  <a id="committee" />
  <section>
    <div class="container">
      <div class="row" v-for="comm in committees">
        <div class="col-lg-3">
          <h1 v-if="comm.group" class="main-section-heading mb-5">
            {{ comm.group }}
          </h1>
        </div>
        <div class="col-lg-9">
          <ProfileGrid
            itemWidth="256px"
            :items="comm.items"
            @more="(x) => (showProfile = x)"
          />
        </div>
      </div>
    </div>
  </section>
  <Teleport to="body">
    <Modal :show="!!showProfile" @close="() => (showProfile = undefined)">
      <div class="profile-modal-content">
        <div
          class="image"
          :style="`--profile-bg-image: url('${showProfile!.photo}')`"
        ></div>
        <div class="text">
          <div class="title">{{ showProfile!.title }}</div>
          <div class="name">{{ showProfile!.name }}</div>
          <div class="qualification">{{ showProfile!.qualifications }}</div>
          <HorizontalRule class="my-4" />
          <p class="intro">{{ showProfile!.intro }}</p>
        </div>
      </div>
    </Modal>
  </Teleport>
</template>

<style scoped lang="scss">
@import '@/assets/css/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

section {
  padding-top: 200px;
  padding-bottom: 100px;
  font-size: 18px;
  font-weight: 500;
  //color: white;
  //background-color: $primary;
  //--bs-body-color: white;
  //--bs-body-bg: #{$primary};
  //
  //.main-section-heading {
  //  color: white;
  //}
}

::v-deep(.modal-container) {
  height: 100%;
  width: 100%;
  max-height: 800px;
  max-width: 1200px;

  @include media-breakpoint-down(md) {
    border-radius: 0;
    max-height: unset;
    max-width: unset;
  }
}

::v-deep(.closeBtn) {
  color: $primary;

  @include media-breakpoint-down(md) {
    svg {
      width: 48px;
      height: 48px;
    }
  }
}

.profile-modal-content {
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  > * {
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    overflow-y: auto;

    > * {
      width: 100%;
    }
  }

  .text {
    padding: 90px 70px 90px 140px;
    width: 55%;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(lg) {
      padding: 48px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      height: unset;
      padding: 70px;
    }
  }

  .image {
    width: 45%;
    background-color: var(--bs-gray-300);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: var(--profile-bg-image);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

    @include media-breakpoint-down(md) {
      height: min(100vw, 400px);
      width: 100%;
      background-size: contain;
      border-radius: 0;
    }
  }

  .title {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    color: $primary;
  }

  .name {
    font-size: 35px;
    font-weight: 700;
  }

  .qualification {
    font-size: 18px;
    font-weight: 600;
    white-space: pre-wrap;
  }

  .intro {
    white-space: pre-wrap;
    font-size: 18px;
    font-weight: 500;
    overflow-y: auto;
    flex-grow: 1;
  }
}
</style>
