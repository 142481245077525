<script setup>
const baseURL = useRuntimeConfig().app.baseURL;
const imgCover = `${baseURL}img/stock/Cover.jpg`;
const img1770199553 = `${baseURL}img/stock/1770199553.jpg`;

const event = useRequestEvent();
if (event?.node?.res) {
  if (process.env['NODE_ENV'] === 'production') {
    event.node.res.setHeader(
      'Cache-Control',
      'public, max-age=300, stale-while-revalidate',
    );
  }
}
</script>

<template>
  <NuxtLayout>
    <BannerWithTitle
      :title="`Building a Functioning Society through Peter F. Drucker's \nLegacy of Management as a Liberal Art`"
      :background="`url('${imgCover}') no-repeat center`"
      background-scrim="rgba(25, 40, 30, 0.67)"
    />
    <LandingAbout />
    <BannerWithTitle :background="`url('${img1770199553}') no-repeat center`" />
    <LandingOurMission />
    <LandingScopeOfServices />
    <!--  <a id="executive-education" />-->
    <!--  <TextSection4 />-->
    <!--  <a id="program-elements" />-->
    <!--  <TextSection5 />-->
    <LandingNewsPreview />
    <LandingCommittee />
    <!--  <a id="activities" />-->
    <!--  <TextSection7 />-->
    <LandingLinks />
  </NuxtLayout>
</template>
