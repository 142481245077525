<script setup lang="ts">
const props = defineProps({
  image: String,
  imagePosition: {
    type: String,
    enum: ['left', 'right'],
  },
  overflow: {
    type: Boolean,
    default: true,
  },
  imageColSize: {
    type: Number,
    default: 6,
  },
  textColSize: {
    type: Number,
    default: (props: any) => 12 - props.imageColSize,
  },
  noPadding: {
    type: Boolean,
  },
  imageHeight: {
    type: String,
    default: '550px',
  },
  imageAspectRatio: {
    type: Number,
    default: 4 / 3,
  },
  objectFit: {
    type: String,
    default: 'cover',
  },
});

const imageCol = computed(() => ({ [`col-xl-${props.imageColSize}`]: true }));
const textCol = computed(() => ({
  [`col-xl-${props.textColSize}`]: true,
  'text-col': !props.noPadding,
}));
</script>

<template>
  <div class="container image-row" :class="imagePosition">
    <div class="row">
      <div :class="imageCol">
        <div
          class="img-container"
          :class="{
            overflow,
            left: imagePosition === 'left',
            right: imagePosition === 'right',
          }"
        >
          <img :src="image" />
        </div>
      </div>
      <div :class="textCol">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.image-row {
  padding-top: 100px;

  &.right > .row {
    flex-direction: row-reverse;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 100px;

    &.right .text-col {
      padding-left: 0;
    }
    &.left .text-col {
      padding-right: 0;
    }
  }
}

.text-col {
  display: flex;
  align-items: center;
  padding-top: 60px;

  @include media-breakpoint-up(lg) {
    padding: 4rem 120px;
  }
}

.img-container {
  position: relative;
  height: v-bind(imageHeight);

  img {
    width: 100%;
    height: 100%;
    object-fit: v-bind(objectFit);
    object-position: center;
    border-radius: 20px;
  }

  @include media-breakpoint-down(xl) {
    height: auto;
    img {
      aspect-ratio: v-bind(imageAspectRatio);
      height: auto;
    }
  }

  &.overflow {
    @include media-breakpoint-up(xl) {
      img {
        position: absolute;
        top: 0;
        width: 50vw;
      }

      &.left img {
        right: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.right img {
        left: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
</style>
