<template>
  <section>
    <a id="links" />
    <div class="container">
      <div style="margin-bottom: 60px">
        <div class="heading">Links to Host Organization and Partners</div>
        <!--        <div class="caption">-->
        <!--          Leave your contact and we will contact you very soon.-->
        <!--        </div>-->
      </div>
      <div>
        <div class="caption">
          Hosted by The Hang Seng University of Hong Kong (HSUHK)
        </div>
        <CtaButton target="_blank" href="https://www.hsu.edu.hk/">
          About HSUHK
        </CtaButton>
        <div class="caption">Partnership with MLA Academy</div>
        <CtaButton target="_blank" href="https://www.mlaacademy.com.hk">
          About MLA Academy
        </CtaButton>
        <div class="caption">Understand the World with Peter F. Drucker</div>
        <CtaButton target="_blank" href="https://www.mlaacademy.org/">
          About mlaacademy.org
        </CtaButton>
        <div class="caption">
          Partnership with Institute of Continuing and Professional Education
          (ICAPE)
        </div>
        <CtaButton target="_blank" href="https://icape.hsu.edu.hk/">
          About ICAPE
        </CtaButton>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

section {
  background: #f1f1f1;
  padding: 144px 16px;

  .heading {
    color: $primary;
    font-size: 40px;
    font-weight: 700;
  }

  .caption {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 1em;
  }
}
</style>
<script setup lang="ts"></script>
